
import { api } from "@/api/api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import "@/shared/styles/toastui-editor.scss";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import "@toast-ui/editor/dist/toastui-editor.css";
import { defineComponent, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CommunicationTemplateModal",
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();

    const rateRegisterYear = ref<number>(new Date().getFullYear());

    const handleSubmit = async () => {
      const isValid = getValidatableRef(refs.connectForm)?.validate();
      if (!isValid) {
        openNotification(store, NotificationItemType.Error, "Mangler år.");
        return;
      }

      await api.clientaccountrate.createClientAccountRatesAsync({ year: +rateRegisterYear.value });
      openNotification(store, NotificationItemType.Success, "Satsregister er opprettet");
      emit("close");
    };

    return {
      rateRegisterYear,
      handleSubmit,
    };
  },
});
