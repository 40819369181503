var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.onSubmit
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('section', [_c('h3', [_vm._v("Sentrale forbundskurs")]), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "data-cy": "centralRatesShared",
      "label": "AdmDelt_S",
      "rules": _vm.validateEmptyOrNonNegative,
      "type": "number",
      "suffix": "kr"
    },
    model: {
      value: _vm.rates.centralCourseRates.participantPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.rates.centralCourseRates, "participantPrice", $$v);
      },
      expression: "rates.centralCourseRates.participantPrice"
    }
  }), _c('v-text-field', {
    attrs: {
      "data-cy": "centralRatesTime",
      "label": "AdmTime_S",
      "rules": _vm.validateEmptyOrNonNegative,
      "type": "number",
      "suffix": "kr"
    },
    model: {
      value: _vm.rates.centralCourseRates.hourlyPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.rates.centralCourseRates, "hourlyPrice", $$v);
      },
      expression: "rates.centralCourseRates.hourlyPrice"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "data-cy": "centralRatesPercentage",
      "label": "Adm%_S",
      "rules": [].concat(_vm.validateMinimumValue(0), _vm.validateMaximumValue(100)),
      "type": "number",
      "suffix": "%"
    },
    model: {
      value: _vm.rates.centralCourseRates.percentageOfTotalCostsPerCourse,
      callback: function callback($$v) {
        _vm.$set(_vm.rates.centralCourseRates, "percentageOfTotalCostsPerCourse", $$v);
      },
      expression: "rates.centralCourseRates.percentageOfTotalCostsPerCourse"
    }
  }), _c('v-text-field', {
    attrs: {
      "data-cy": "centralRatesMax",
      "label": "Tak_S",
      "rules": _vm.validateEmptyOrNonNegative,
      "type": "number",
      "suffix": "kr"
    },
    model: {
      value: _vm.rates.centralCourseRates.maximumAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.rates.centralCourseRates, "maximumAmount", $$v);
      },
      expression: "rates.centralCourseRates.maximumAmount"
    }
  })], 1)], 1)], 1), _c('section', [_c('h3', [_vm._v("Lokale forbundskurs")]), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "data-cy": "localRatesShared",
      "label": "AdmDelt_L",
      "rules": _vm.validateEmptyOrNonNegative,
      "type": "number",
      "suffix": "kr"
    },
    model: {
      value: _vm.rates.localCourseRates.participantPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.rates.localCourseRates, "participantPrice", $$v);
      },
      expression: "rates.localCourseRates.participantPrice"
    }
  }), _c('v-text-field', {
    attrs: {
      "data-cy": "localRatesTime",
      "label": "AdmTime_L",
      "rules": _vm.validateEmptyOrNonNegative,
      "type": "number",
      "suffix": "kr"
    },
    model: {
      value: _vm.rates.localCourseRates.hourlyPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.rates.localCourseRates, "hourlyPrice", $$v);
      },
      expression: "rates.localCourseRates.hourlyPrice"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "data-cy": "localRatesPercentage",
      "label": "Adm%_L",
      "rules": [].concat(_vm.validateMinimumValue(0), _vm.validateMaximumValue(100)),
      "type": "number",
      "suffix": "%"
    },
    model: {
      value: _vm.rates.localCourseRates.percentageOfTotalCostsPerCourse,
      callback: function callback($$v) {
        _vm.$set(_vm.rates.localCourseRates, "percentageOfTotalCostsPerCourse", $$v);
      },
      expression: "rates.localCourseRates.percentageOfTotalCostsPerCourse"
    }
  }), _c('v-text-field', {
    attrs: {
      "data-cy": "localRatesMax",
      "label": "Tak_L",
      "rules": _vm.validateEmptyOrNonNegative,
      "type": "number",
      "suffix": "kr"
    },
    model: {
      value: _vm.rates.localCourseRates.maximumAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.rates.localCourseRates, "maximumAmount", $$v);
      },
      expression: "rates.localCourseRates.maximumAmount"
    }
  })], 1)], 1)], 1), _c('section', [_c('h3', [_vm._v("Produktbidrag")]), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "data-cy": "commonMeasures",
      "label": "Fellestiltak",
      "rules": _vm.validateEmptyOrNonNegative,
      "type": "number",
      "suffix": "kr"
    },
    model: {
      value: _vm.rates.productContributions.commonActions,
      callback: function callback($$v) {
        _vm.$set(_vm.rates.productContributions, "commonActions", $$v);
      },
      expression: "rates.productContributions.commonActions"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "data-cy": "organizationCourse",
      "label": "Forbundskurs",
      "rules": _vm.validateEmptyOrNonNegative,
      "type": "number",
      "suffix": "kr"
    },
    model: {
      value: _vm.rates.productContributions.organizationCourses,
      callback: function callback($$v) {
        _vm.$set(_vm.rates.productContributions, "organizationCourses", $$v);
      },
      expression: "rates.productContributions.organizationCourses"
    }
  })], 1)], 1)], 1), _c('section', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "data-cy": "govSubsidy",
      "label": "Statsstøtte",
      "rules": _vm.validateEmptyOrNonNegative,
      "type": "number",
      "suffix": "kr"
    },
    model: {
      value: _vm.rates.governmentContribution,
      callback: function callback($$v) {
        _vm.$set(_vm.rates, "governmentContribution", $$v);
      },
      expression: "rates.governmentContribution"
    }
  }), _c('v-text-field', {
    attrs: {
      "data-cy": "allocation",
      "label": "Tildeling",
      "rules": _vm.validateEmptyOrNonNegative,
      "type": "number",
      "suffix": "kr"
    },
    model: {
      value: _vm.rates.grantedAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.rates, "grantedAmount", $$v);
      },
      expression: "rates.grantedAmount"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "data-cy": "balance",
      "label": "Saldo",
      "rules": _vm.validateEmptyOrNonNegative,
      "type": "number",
      "suffix": "kr"
    },
    model: {
      value: _vm.rates.balance,
      callback: function callback($$v) {
        _vm.$set(_vm.rates, "balance", $$v);
      },
      expression: "rates.balance"
    }
  }), _c('v-switch', {
    attrs: {
      "data-cy": "isActive",
      "label": _vm.rates.isActive ? 'Aktiv' : 'Inaktiv',
      "color": "primary",
      "inset": "",
      "hide-details": ""
    },
    model: {
      value: _vm.rates.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.rates, "isActive", $$v);
      },
      expression: "rates.isActive"
    }
  })], 1)], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }