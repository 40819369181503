var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', [_vm._v(" Du oppretter nå et nytt satsregister for et nytt år. Når du har opprettet et års satsregister så kan du ikke senere slette satsregisteret. Er du sikker på at du vil gå videre med opprettelsen? ")])], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "data-cy": "year",
      "type": "number",
      "readonly": _vm.readonly,
      "label": "År",
      "rules": [function (v) {
        return !!v || 'Fyll inn år for nytt satsregister';
      }],
      "required": ""
    },
    model: {
      value: _vm.rateRegisterYear,
      callback: function callback($$v) {
        _vm.rateRegisterYear = $$v;
      },
      expression: "rateRegisterYear"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }